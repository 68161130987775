<template>
  <div class="container">
    <table>
      <tbody>
        <tr class="basetr">
          <td class="first-line-col1">
            <div class="avatar"><img :src="user.avatar!=null ? user.avatar : '../../assets/logo.jpg'" alt=""></div>
          </td>
          <td class="first-line-col2">
            <a-upload
              :multiple="false"
              accept="image/jpg,image/jpeg,image/png"
              list-type="picture-card"
              :file-list="fileList"
              @change="fileChange"
              @preview="handlePreview"
              style="margin-left: 20px;"
              @remove="handleRemove"
            >
            <div v-if="fileList.length < 1">
              <plus-outlined />
              <div class="ant-upload-text">
                更换头像
              </div>
            </div>
            </a-upload>
            <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancel">
              <img alt="example" style="width: 100%" :src="previewImage" />
            </a-modal>
          </td>
          <a-divider></a-divider>
        </tr>
        <tr class="basetr">
          <td class="second-line-col1"><span class="title">用户名</span></td>
          <td class="second-line-col2"><span>{{user.userName}}</span></td>
        </tr>
        <tr class="basetr">
          <td class="second-line-col1"><span class="title">上次登陆时间</span></td>
          <td class="second-line-col2"><span>{{user.lstLoginTime}}</span></td>
        </tr>
        <tr class="basetr">
          <td class="second-line-col1"><span class="title">账号注册时间</span></td>
          <td class="second-line-col2"><span>{{user.registerTime}}</span></td>
        </tr>
        <tr class="basetr">
          <td class="second-line-col1"><span class="title">电子邮件</span></td>
          <td class="second-line-col2"><span><a-input v-model:value="user.email" style="width: 200px;" :disabled = "eDisable" /></span></td>
          <td><a-button shape="round" type="primary" @click="changeEmail"><mail-outlined />更绑邮箱</a-button></td>
        </tr>
        <tr class="basetr">
          <td class="second-line-col1"><span class="title">性别</span></td>
          <td class="second-line-col2">
            <a-radio-group v-model:value="user.userSex" :options="sexOptions" />
          </td>
        </tr>
        <tr class="basetr">
          <td class="second-line-col1"><a-button shape="round" class="saveBtn" type="primary" @click="save">保存</a-button></td>
        </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
  import * as ACCOUNT from '../../api/account.js';
  import { PlusOutlined, MailOutlined } from '@ant-design/icons-vue';
  export default {
    name: 'BaseSetting',
    components: {
      PlusOutlined,
      MailOutlined
    },
    data() {
      return {
        fileList: [],
        previewVisible: false,
        previewImage: '',
        user: {
          userId: null,
          userName: null,
          email: null,
          avatar: null,
          userSex: null,
          lstLoginTime: null,
          registerTime: null
        },
        eDisable: true,
        sexOptions: [
        { label: '男', value: 1 },
        { label: '女', value: 0 },
        ]
      }
    },
    mounted() {
      this.loadUserInfo()
    },
    methods: {
      fileChange({ fileList }) {
        this.fileList = fileList;
      },
      loadUserInfo() {
        ACCOUNT.srhUserAttrInfoByUId().then(res=>{
          // console.log('user info', res)
          if(res.data.code == 200) {
            this.user.userId = res.data.data.userId 
            this.user.userName = res.data.data.userName
            this.user.email = res.data.data.userMail
            this.user.avatar = res.data.data.avatar
            this.user.userSex = res.data.data.userSex
            this.user.lstLoginTime = res.data.data.lstLoginTime
            this.user.registerTime = res.data.data.registerTime
          }
        })
      },
      changeEmail() {
        this.eDisable = false
      },
      save() {
        let params = new FormData()
        if(this.user.userSex!==null) {
          params.append('userSex', this.user.userSex)
        }
        if(this.user.email !== null) {
          params.append('email', this.user.email)
        }
        if(this.fileList.length>0) {
          params.append('avatar', this.fileList[0].originFileObj)
        }
        // console.log('email', this.user.email)
        ACCOUNT.uptUserInfo(params).then(res=>{
          // console.log('update', res)
          if(res.data.code == 200) {
            this.$message.success('更新成功,下次登录后头像设置生效')
            this.loadUserInfo()
            this.eDisable = true
            this.fileList= []
          }else {
            this.$message.warning(res.data.msg)
          }
        })
      },
      getBase64(file) {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => resolve(reader.result);
          reader.onerror = error => reject(error);
        });
      },
      async handlePreview(file) {
        if (!file.url && !file.preview) {
          file.preview = await this.getBase64(file.originFileObj);
        }
        this.previewImage = file.url || file.preview;
        this.previewVisible = true;
      },
      handleCancel() {
        this.previewVisible = false;
      },
      beforeUpload(file) {
        console.log('file check', file)
        const isLt2M =  file.size/1024/1024 < 2
        if(!isLt2M){
          this.$message.error(file.name+'文件大小超出限制，请修改后重新上传')
          return false
        }
        return true
      },
      handleRemove(file) {
        const index = this.fileList.indexOf(file);
        const newFileList = this.fileList.slice();
        newFileList.splice(index, 1);
        this.fileList = newFileList;
      }
    },
  }
</script>

<style scoped>
  .container {
    width: 625px;
    height: 100%;
    margin-left: 20px;
  }
  .basetr {
    border-bottom:1px solid rgb(232, 229, 229);
  }
  .first-line-col1 {
    height: 120px;
    width: 150px;
  }
  .avatar {
    width: 100px;
    height: 100px;
  }
  .avatar img {
    width: 100%;
    height: 100%;
    border: 1px solid rgb(232, 229, 229);
    border-radius: 50%;
  }
  .first-line-col2 {
    height: 120px;
    width: 100%;
  }
  .second-line-col1 {
    height: 80px;
    width: 150px;
    text-align: left;
  }
  .second-line-col2 {
    height: 80px;
    text-align: left;
  }
  .title {
    font-size: 15px;
    color: #969696;
  }
  .saveBtn {
    color: #FFF;
    background-color: #42c02e;
    border: none;
  }
</style>